import { Row } from "antd";
import { comemosTodos } from "../src/assets/images";

const Closed = () => {
  return (
    // <div className="closed">
    // <h1>Sorry, we are closed</h1>
    // </div>
    <Row justify={"center"}>
      <img
        src={comemosTodos}
        style={{
          maxWidth: "100%",
        }}
      />
      <h3>
        El periodo de actualización es el 1 al 29 de febrero del 2024, te
        invitamos a estar pendiente de las redes sociales de la Secretaría de
        Bienestar de Quintana Roo y en{" "}
        <a href="https://qroo.gob.mx/sebien/comemostodasytodos">
          qroo.gob.mx/sebien/comemostodasytodos
        </a>
      </h3>
    </Row>
  );
};

export default Closed;
