import { IFlowyProject, IGizmo } from "flowy-3-core";
import { LoginHeader, DownloadVoucher } from "../custom";
import config from "../../config";

// Server
const qForm = "65a7f35a02d68b001ee33095";
const signUpForm = "65a7f37902d68b001ee332bb";

// Server new
// const qForm = "645bd0e9be3d3b001d6bf817";
// const signUpForm = "645bce51e3152c001d59aee7";

// Local
// const qForm = "63e35685a6528a001dbc013a";
// const signUpForm = "63fc0a8b851eff0025084777";

const viewWithFormsIndex: IGizmo = {
  fid: "view-with-forms-index",
  type: "view",
  label: "Bienvenido (a)",
  ops: {
    form: {
      privacyPolicy: {
        title: "Aviso de Privacidad",
        policy: 'AVISO DE PRIVACIDAD INTEGRAL DEL ANEXO XI “FORMATO DE CUIS”, CONTENIDO EN LAS REGLAS DE OPERACIÓN DEL PROGRAMA “COMEMOS TOD@S“ \n\n La Secretaría de Bienestar del Estado de Quintana Roo, a través de la Agencia de Seguridad Alimentaria, con domicilio en la Plaza Hollywood primer piso, Avenida Palenque s/n, entre Avenida Cobá y Avenida Xcaret, Supermanzana 35, Manzana 01, Lote 01, Código Postal 77505 de la Ciudad de Cancún, Quintana Roo, México, comunica que es la responsable del tratamiento de los Datos Personales que nos proporcione, los cuales estarán protegidos, con fundamento en la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados y en la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados para el Estado de Quintana Roo y demás normativa aplicable en la materia. \n\n',
        onCreate: true,
      }
    }
  },
  gizmos: [
    // {
    //   fid: "g0",
    //   type: "text",
    //   label: "Forms Index Papaloy",
    // },
    {
      fid: "g10",
      type: "button",
      label: "Ir al CUIS",
      ops: {
        button: {
          operation: "navigation.link",
          navigation: {
            // path: "/rg?rgfid=63e35685a6528a001dbc013a",
            // path: "/rg?rgfid=63f5234b4732de0015a3202a",
            path: `/rg?rgfid=${qForm}&fid=ok`,
          },
        },
      },
    },
    // {
    //   fid: "g11",
    //   type: "button",
    //   label: "Descargar Comprobante de Registro",
    //   ops: {
    //     button: {
    //       operation: "download",
    //       download: {
    //         // url: "http://192.168.15.6:8000/public/forms/63e35685a6528a001dbc013a/filled_forms/pdf/{{user.q8}}",
    //         // url: "http://192.168.15.6:8000/public/forms/63f5234b4732de0015a3202a/filled_forms/pdf/{{user.q15}}",
    //         url: "https://api.flowy.in/public/forms/63f5234b4732de0015a3202a/filled_forms/pdf/{{user.q15}}",
    //         filename: "recibo.pdf",
    //       },
    //     },
    //   },
    // },
    {
      fid: "g111",
      type: "custom",
      label: "Download Voucher",
      ops: {
        button: {
          operation: "download",
          download: {
            url: `${config.apiUrl}/public/forms/${qForm}/filled_forms/pdf/{{user.q15}}?pdfFolio=beneficiario_ct`,
            filename: "recibo.pdf",
          },
        },
        custom: {
          customParams: {
            dataUrl: `${config.apiUrl}/main_api/v1/root_gizmo/data/${qForm}/d`,
          },
          component: DownloadVoucher,
        },
      },
    },
    // {
    //   fid: "g1",
    //   type: "index",
    //   label: "Forms index",
    //   ops: {
    //     index: {
    //       type: "table",
    //       rowType: "forms",
    //     },
    //   },
    // },
    // {
    //   fid: "g2",
    //   type: "button",
    //   label: "Go to registers index",
    //   ops: {
    //     button: {
    //       operation: "navigation.link",
    //       navigation: {
    //         path: "/r",
    //       },
    //     },
    //   },
    // },
  ],
};

const viewWithRegistersIndex: IGizmo = {
  fid: "view-with-registers-index",
  type: "view",
  label: "View with registers index",
  gizmos: [
    {
      fid: "g0",
      type: "text",
      label: "Registers Index",
    },
    // {
    //   fid: "g1",
    //   type: "index",
    //   label: "Registers Index",
    //   ops: {
    //     index: {
    //       rowType: "form.registers",
    //     },
    //   },
    // },
  ],
};

const viewNavbar: IGizmo = {
  fid: "view-navbar",
  type: "view",
  label: "View with navbar",
  ops: {
    displayLabel: false,
  },
  gizmos: [
    {
      fid: "q100",
      type: "row",
      label: "Row 1",
      ops: {
        row: {
          justify: "end",
          columns: {
            xl: 4,
            l: 4,
            m: 3,
            s: 2,
          },
        },
      },
      gizmos: [
        {
          fid: "g10",
          type: "text",
          label: "{{user.q3}} {{user.q4}} {{user.q5}}",
        },
        {
          fid: "g0",
          type: "button",
          label: "Cerrar sesión",
          ops: {
            button: {
              operation: "auth.logout",
              confirmation: {
                title: "Cerrar sesión",
                description: "¿Estás seguro que quieres cerrar sesión?",
              },
            },
          },
        },
      ],
    },
  ],
};

const loginForm: IGizmo = {
  fid: "login-form-view-fid",
  type: "view",
  label: "Login Form",
  ops: {
    displayLabel: false,
  },
  gizmos: [
    {
      fid: "g100",
      type: "custom",
      label: "Login Header",
      ops: {
        custom: {
          component: LoginHeader,
        },
      },
    },
    {
      fid: "login-form-fid",
      type: "form",
      label: "Login",
      ops: {
        displayLabel: false,
        form: {
          saveButtonLabel: "Iniciar Sesión",
          displayCancelButton: {
            isDisplayed: false,
          },
          auth: {
            endpoint: "login",
            // rootGizmoFid: "63fc0a8b851eff0025084777",
            // rootGizmoFid: "640668bf4a303c000a1da36f",
            rootGizmoFid: signUpForm,
          },
        },
      },
      gizmos: [
        {
          fid: "q2",
          type: "text_field",
          label: "CURP",
          uid: "username",
          ops: {
            textField: {
              toUppercase: true,
            },
          },
        },
        {
          fid: "added_password",
          type: "text_field",
          label: "NIP",
          uid: "password",
          ops: {
            textField: {
              password: {
                isPassword: true,
              },
            },
          },
        },
      ],
    },
    // {
    //   fid: "g4",
    //   type: "text",
    //   label: "¿No tienes cuenta?",
    // },
    // {
    //   fid: "g3",
    //   type: "button",
    //   label: "Regístrate",
    //   ops: {
    //     button: {
    //       operation: "navigation.link",
    //       navigation: {
    //         path: "/sign_up",
    //       },
    //     },
    //   },
    // },
    {
      fid: "g10",
      type: "row",
      label: "Row",
      ops: {
        // displayLabel: false,
        // row: {
        //   columns: {
        //     xl: 5,
        //     l: 2,
        //     m: 2,
        //     s: 2,
        //   },
        // },
      },
      gizmos: [
        {
          fid: "g4",
          type: "text",
          label: "¿No tienes cuenta?",
        },
        {
          fid: "g3",
          type: "button",
          label: "Regístrate",
          ops: {
            button: {
              operation: "navigation.link",
              navigation: {
                path: "/sign_up",
              },
            },
          },
        },
      ],
    },
  ],
};

// const loginForm: IGizmo = {
//   fid: "login-form-fid",
//   type: "form",
//   label: "Login",
//   ops: {
//     form: {
//       auth: {
//         endpoint: "login",
//         rootGizmoFid: "63fc0a8b851eff0025084777",
//       },
//     },
//   },
//   gizmos: [
//     {
//       fid: "q0",
//       type: "text_field",
//       label: "Usuario",
//       uid: "user",
//     },
//     {
//       fid: "q1",
//       type: "text_field",
//       label: "Password",
//       uid: "password",
//       ops: {
//         textField: {
//           password: {
//             isPassword: true,
//           },
//         },
//       },
//     },
//   ],
// };

const signupForm: IGizmo = {
  fid: "signup-form-fid",
  type: "form",
  label: "Signup",
  ops: {
    form: {
      auth: {
        endpoint: "signup",
        // rootGizmoFid: "63fc0a8b851eff0025084777",
        // rootGizmoFid: "640668bf4a303c000a1da36f",
        rootGizmoFid: signUpForm,
      },
    },
  },
};

const beneficiaryProject: IFlowyProject = {
  fid: "deafult-project",
  type: "project",
  label: "Default project",
  views: {
    gizmos: [viewWithFormsIndex, viewWithRegistersIndex, loginForm, signupForm],
  },
  navbar: {
    gizmos: [viewNavbar],
  },
  navigation: {
    routes: [
      {
        fid: "r1",
        type: "route",
        label: "Forms Index",
        uid: "forms_index",
        ops: {
          route: {
            rootGizmoFid: viewWithFormsIndex.fid,
            index: true,
          },
        },
      },
      {
        fid: "r2",
        type: "route",
        label: "Registers Index",
        uid: "registers_index",
        ops: {
          route: {
            rootGizmoFid: viewWithRegistersIndex.fid,
            index: false,
            path: "/r",
            // TODO: parameters definition
          },
        },
      },
      {
        fid: "r3",
        type: "route",
        label: "Login",
        uid: "login",
        ops: {
          route: {
            rootGizmoFid: loginForm.fid,
            index: false,
            path: "/login",
          },
        },
      },
      {
        fid: "r4",
        type: "route",
        label: "Sign Up",
        uid: "sign_up",
        ops: {
          route: {
            rootGizmoFid: signupForm.fid,
            index: false,
            path: "/sign_up",
          },
        },
      },
    ],
  },
};

export default beneficiaryProject;
